import DataNotFound from 'components/DataNotFound';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import MaskedInput from 'react-text-mask';
import {
  Button, Card,
  CardBody, CardFooter, CardHeader,
  CardTitle, Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter, ModalHeader, Row,
  ButtonGroup
} from 'reactstrap';
import LoadingCard from '../../components/Loading/LoadingCard';
import Api from '../../config/Api';
import Comprovante from './Comprovante';
import DeliveryCard from './DeliveryCard';
import DeliveryLocation from './DeliveryLocation';
import Moment from 'moment';
import Timeline from './Timeline';
import Truck from '../../assets/img/truck.png';
import Notification from '../../components/Notifications/Notification';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Alertas from './Alertas';

const Tracking = (props) => {
  const location = useLocation();
  const [api, setapi] = useState(null);
  const [dataLoaded, setdataLoaded] = useState(false);
  const [documents, setdocuments] = useState([]);
  const [count, setCount] = useState(0);
  
  // modais
  const [timeline, settimeline] = useState(false);
  const [deliveryLocation, setdeliveryLocation] = useState(false);
  const [comprovante, setcomprovante] = useState(false);
  const [alertas, setalertas] = useState(false);


  const [documentSelected, setdocumentSelected] = useState(null);
  const [documento, setdocumento] = useState('');
  const [codigoRastreio, setcodigoRastreio] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [periodSelected, setPeriodSelected] = useState('SEMANAL')
  const [periodDateStart, setPeriodDateStart] = useState(Moment().toDate())
  const [periodDateEnd, setPeriodDateEnd] = useState(Moment().subtract(1, 'week').toDate())

  const abrirNotificacaoErro = (message) => Notification.createNotification('error', message);
  const abrirNotificacaoSuccess = (message) => Notification.createNotification('success', message);

  useEffect(() => {
    const getTrackingInfo = async () => {
      const searchValue = qs.parse(location.search, { ignoreQueryPrefix: true });
      const checkParams = () => {
        if (searchValue.codigoRastreio) {
          return true;
        }
        if (searchValue.documentoDestinatario) {
          return true;
        }
        if (searchValue.pessoaId) {
          return true;
        }
        if (searchValue.rotaPontoId) {
          return true;
        }
        return false;
      }

      if (!checkParams()) {
        setdataLoaded(true);
        return;
      }

      try {
        const { data } = await api.searchDeliveriesAdvanced(searchValue);
        setdocuments(data.rows || []);
        setCount(data.count)
        setdataLoaded(true);
        abrirNotificacaoSuccess('Consulta realizada com sucesso')
      } catch (error) {
        abrirNotificacaoErro('Não foi possível buscar os dados da entrega.')
        setdataLoaded(true);
      }

    }

    if (api) {
      getTrackingInfo();
    }
  }, [api, location.search])

  useEffect(() => {
    async function loginAsync() {
      try {
        const { data } = await Api.login();

        if (data.token) {
          const { token } = data;

          setapi(new Api(token, (err) => Promise.reject(err)));

        } else {
          props.history.push('/');
          window.alert('Não foi possível buscar os dados da entrega.');
        }
      } catch (error) {
        props.history.push('/');
        window.alert('Não foi possível buscar os dados da entrega.');
      }
    }

    if (!api && location.search && !dataLoaded) {
      loginAsync();
    } else if (!location.search && dataLoaded) {
      setdataLoaded(false);
      setdocuments([]);
    }
  }, [api, dataLoaded, location.search, props.history])


  const openModal = (document, property) => {
    setdocumentSelected(document);

    if (property === 'timeline') settimeline(true);
    if (property === 'comprovante') setcomprovante(true);
    if (property === 'deliveryLocation') setdeliveryLocation(true);
    if (property === 'alertas') setalertas(true);
  }

  const toggleTimeline = () => {
    settimeline(!timeline)
  }

  const toggleComprovante = () => {
    setcomprovante(!comprovante);
  }

  const toggleDeliveryLocation = () => {
    setdeliveryLocation(!deliveryLocation);
  }

  const toggleAlertas = () => {
    setalertas(!alertas);
  }

  const getCnpjCpfMask = (rawValue) => {
    const unmakedValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
    if (unmakedValue.length > 11) {
      return [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/];
    }
    return [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];
  }

  const handleInputChange = (event, set) => {
    const { value } = event.target;
    set(value)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setCount(0)
    const url = ['/?'];

    if (!documento && !codigoRastreio) {
      return abrirNotificacaoErro('Informe o CNPJ/CPF ou Código de Rastreio')
    }

    if (documento) {
      url.push(`documentoDestinatario=${documento}`);
    }

    if (codigoRastreio) {
      url.push(`codigoRastreio=${codigoRastreio}`);
    }

    if (periodDateStart && periodDateEnd) {
      if (documento || codigoRastreio) {
        url.push('&');
      }
      url.push(`dataInicio=${Moment(periodDateStart).format('YYYY-MM-DD')}&dataFim=${Moment(periodDateEnd).format('YYYY-MM-DD')}`);
    } else{
      return abrirNotificacaoErro('Selecione o período de consulta')
    }

    props.history.push(url.join(''));
  }

  const handleClearSearch = (e) => {
    props.history.push('/');
  }

  const handlePeriod = (period) => {
    switch (period) {
      case 'SEMANAL': 
        setPeriodSelected('SEMANAL')
        setPeriodDateStart(Moment().subtract(1, 'week').toDate())
        setPeriodDateEnd(Moment().toDate())
        break
      case 'QUINZENAL': 
        setPeriodSelected('QUINZENAL')
        setPeriodDateStart(Moment().subtract(2, 'week').toDate())
        setPeriodDateEnd(Moment().toDate())
        break
      case 'MENSAL': 
        setPeriodSelected('MENSAL')
        setPeriodDateStart(Moment().subtract(1, 'month').toDate())
        setPeriodDateEnd(Moment().toDate())
        break
      default:
        setPeriodSelected('PERSONALIZADO')
    }
  }

  const onChangePersonalizado = (dates) => {
    const [start, end] = dates;
    setPeriodDateStart(start);
    setPeriodDateEnd(end);
    if(start && end){
      handlePeriod('PERSONALIZADO')
    }
  };

  const renderSearchForm = () => (
      <Row>
        <Col md='6'>
          <img
            src={Truck}
            alt="Tracking"
            height="450px"
            width="450px"
            style={{ objectFit: 'contain', opacity: '0.5' }}
          />
        </Col>
        <Col md='6' style={{display:'flex', justifyContent: 'center', flexDirection: 'column'}}>
          <Form onSubmit={handleSubmit}>
            <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', marginBottom: 30}}>
              <span style={{fontSize: 50, fontWeight: 'bold'}}>Tracking</span>
              <span style={{fontSize: 15, fontWeight: 'lighter'}}>Receba atualizações de entrega e rastreie seu pedido</span>
            </div>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="cnpj">CNPJ/CPF:</Label>
                  <MaskedInput
                    mask={getCnpjCpfMask}
                    className="form-control"
                    guide
                    disabled={!!codigoRastreio}
                    name="documento"
                    id="documento"
                    placeholder="Entre com CNPJ ou CPF"
                    defaultValue={documento}
                    onChange={(event) => { handleInputChange(event, setdocumento) }}
                    render={(ref, props) => (
                      <Input style={{border: "1px solid #023047"}} innerRef={ref} {...props} />
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <div>
                    <Label for="cnpj">Período:</Label>
                    <span style={{fontSize: 12, fontWeight: 'lighter', marginLeft: 8}}>({periodDateStart ? Moment(periodDateStart).format('DD/MM/YYYY') : 'dd/mm/aaaa'} - {periodDateEnd ? Moment(periodDateEnd).format('DD/MM/YYYY') : 'dd/mm/aaaa'})</span>
                  </div>
                  <ButtonGroup style={{gap: 10, width: '100%', flexWrap: 'wrap' }}>
                    <Button
                      color="primary"
                      outline
                      style={{padding: '10px 5px', margin: 0}}
                      onClick={() => handlePeriod('SEMANAL')}
                      active={periodSelected === 'SEMANAL'}
                    >
                      Semanal
                    </Button>
                    <Button
                      color="primary"
                      outline
                      style={{padding: '10px 5px', margin: 0}}
                      onClick={() => handlePeriod('QUINZENAL')}
                      active={periodSelected === 'QUINZENAL'}
                    >
                      Quinzenal
                    </Button>
                    <Button
                      color="primary"
                      outline
                      style={{padding: '10px 5px', margin: 0}}
                      onClick={() => handlePeriod('MENSAL')}
                      active={periodSelected === 'MENSAL'}
                    >
                      Mensal
                    </Button>
                    <div>
                    <DatePicker
                      onChange={onChangePersonalizado}
                      startDate={periodDateStart}
                      endDate={periodDateEnd}
                      selectsRange
                      wrapperClassName='w-auto'
                      dateFormat="dd/MM/yyyy"
                      shouldCloseOnSelect={false}
                      customInput={<Button
                        color="primary"
                        outline
                        style={{ margin: 0}}
                        active={periodSelected === 'PERSONALIZADO'}
                      >
                        Personalizado
                      </Button>}
                    />

                    </div>
                  </ButtonGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <FormGroup>
                  <Label for="cnpj">Código de Rastreamento:</Label>
                  <Input
                    type="number"
                    name="codigoRastreio"
                    id="codigoRastreio"
                    placeholder="Entre com o código de rastreio"
                    defaultValue={codigoRastreio}
                    onChange={(event) => { handleInputChange(event, setcodigoRastreio) }}
                    maxLength={14}
                    disabled={!!documento}
                    style={{border: "1px solid #023047"}}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md='12'>
              <FormGroup>
                <Input 
                  style={{position: 'initial'}} 
                  className='ml-0 mr-1 mt-5' 
                  type="checkbox"
                  defaultChecked={acceptedTerms}
                  onChange={()=> setAcceptedTerms(prev => !prev)}
                />
                <Label check>
                  Aceito os Termos e a Política de Privacidade
                </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Button
                  type='submit'
                  style={{width: '100%'}}
                  disabled={!acceptedTerms}
                  color="danger">
                  <i className="fas fa-search" />
                    {' '}
                  Filtrar
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
  );

  const renderTrackingCard = () => (
    <Card className='card-box-shadow'>
      <CardHeader>
          <CardTitle className={!dataLoaded ? 'text-center' : 'text-left'} tag="h4">Tracking</CardTitle>
          {count > 100 && (
            <div className="content-count" style={{ '--color': '223, 193, 13', fontSize: '1.4vh' }}> 
              <i className="fas fa-exclamation-triangle mr-2" />
              <span>Foram encontrados mais de 100 registros. Refine sua busca para exibir os demais registros.</span>
            </div> 
          )}
      </CardHeader>
      <CardBody>
        {renderCardBodyContent()}
      </CardBody>
      {dataLoaded && (
        <CardFooter className="card-chart">
          <Button
            color="primary"
            onClick={handleClearSearch}>
            Nova Consulta
                    {' '}
            <i className="fas fa-search-plus" />
          </Button>
        </CardFooter>
      )}
    </Card>
  );

  const renderCardBodyContent = () => {
    if (dataLoaded) {
      if (documents.length) {
        return documents.map((document) => (
          <DeliveryCard
            documentSelected={document}
            key={document.id}
            openModal={openModal}
          />
        ))
      } else {
        return <DataNotFound text="Nenhuma entrega encontrada" />;
      }
    } else {
      return <LoadingCard />;
    }
  }

  return (
    <>
      <div className="content">
        <Row className={(!dataLoaded && !location.search) && 'tracking-content'}>
          <Col xs={12}>
            {
              ((!dataLoaded && !location.search) && renderSearchForm())
              || renderTrackingCard()
            }
          </Col>
        </Row>
      </div>
      <Modal className="new-expense-modal" isOpen={timeline} toggle={toggleTimeline}>
        <ModalHeader toggle={toggleTimeline}>Timeline</ModalHeader>
        <ModalBody>
          <Timeline
            modal={toggleTimeline}
            documentSelected={documentSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleTimeline}>Fechar</Button>
        </ModalFooter>
      </Modal>
      <Modal className="new-expense-modal" isOpen={comprovante} toggle={toggleComprovante}>
        <ModalHeader toggle={toggleComprovante}>Comprovante</ModalHeader>
        <ModalBody>
          <Comprovante
            modal={toggleComprovante}
            api={api}
            documentSelected={documentSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleComprovante}>Fechar</Button>
        </ModalFooter>
      </Modal>
      <Modal className="new-expense-modal" isOpen={deliveryLocation} toggle={toggleDeliveryLocation}>
        <ModalHeader toggle={toggleDeliveryLocation}>Local da Entrega</ModalHeader>
        <ModalBody>
          <DeliveryLocation
            modal={toggleDeliveryLocation}
            api={api}
            documentSelected={documentSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleDeliveryLocation}>Fechar</Button>
        </ModalFooter>
      </Modal>
      <Modal className="new-expense-modal" isOpen={alertas} toggle={toggleAlertas}>
        <ModalHeader toggle={toggleAlertas}>Alertas</ModalHeader>
        <ModalBody>
          <Alertas
            modal={toggleAlertas}
            api={api}
            documentSelected={documentSelected}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={toggleAlertas}>Fechar</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default Tracking;