import React, { useEffect, useState } from 'react';
import {
  Row, Col, Button, Badge, Collapse,
} from 'reactstrap';
import Moment from 'moment-timezone';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import useWindowDimensions from '../../utils/useWindowDimensions';

const DeliveryCard = (props) => {
  const {
    dataEmissao,
    inicioDeRota,
    dataEntrega,
    situacaoEntrega,
    destinatarioNome,
    notaFiscal,
    numeroRemessa,
    transportadora,
    previsaoInicial,
    previsaoAtualizada,
    situacaoAndamento,
    remetenteNome,
    redespachoNome,
    showOnlyDayShift,
    showAllTrackingPredictions,
    alertas
  } = props.documentSelected;

  const { width } = useWindowDimensions();
  const [collapse, setCollapse] = useState(false);
  const handleToggleCollapse = () => setCollapse(!collapse);

  useEffect(() => {
    if (width >= 768) {
      setCollapse(true)
    } else {
      setCollapse(false)
    }
  }, [width])

  const prettyfyStatus = (situacao) => {
    if (situacao === 'EM_DIGITACAO') {
      return 'Em Digitação';
    }
    if (situacao === 'EM_OCORRENCIA') {
      return 'Em Ocorrência';
    }
    if (situacao === 'ENTREGUE') {
      return 'Entregue';
    }
    if (situacao === 'EM_ENTREGA') {
      return 'Em Entrega';
    }
    return 'Recebido pela transportadora';
  }

  const mountStatusBadge = (status) => {
    if (status === 'ATRASADA') {
      return {
        background: 'rgb(239, 83, 80) none repeat scroll 0% 0%',
        fontSize: '12px',
      };
    }
    if (status === 'ON_TIME') {
      return {
        background: 'rgb(85, 139, 47) none repeat scroll 0% 0%',
        fontSize: '12px',
      };
    }
    if (status === 'TOLERANCIA') {
      return {
        background: 'rgb(196, 196, 67) none repeat scroll 0% 0%',
        fontSize: '12px',
      };
    }
    return {
      background: '#023047 none repeat scroll 0% 0%',
      fontSize: '12px',
    };
  }

  const mountDateFormat = (date, dayShift = false) => {
    if (date) {
      if (dayShift) {
        const dayShiftFromDate = Moment(date).format('A');
        const dayShiftFormatted = dayShiftFromDate === 'AM' ? 'Manhã' : 'Tarde';
        return `${Moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY')} - ${dayShiftFormatted}`;
      }
      return Moment(date).tz('America/Sao_Paulo').format('DD/MM/YYYY HH:mm');
    }

    return '';
  }

  const getIconStyle = (hasData) => {
    return {
      background: hasData ? '#e63946' : '#9e9e9e',
      color: 'white',
      fontSize: '18px',
    }
  }

  return (
    <Row>
      <Col md="12" className="card delivery-card-tracking">
        <Row>
          <Col md="6">
            <VerticalTimeline animate={false}>
              <VerticalTimelineElement
                position="right"
                className="vertical-timeline-element--work timeline-content"
                contentStyle={{ boxShadow: '0!important' }}
                contentArrowStyle={{ display: 'none' }}
                date={mountDateFormat(dataEmissao)}
                iconStyle={getIconStyle(dataEmissao)}
                iconClassName='cursor-default'
                icon={<i className="fas fa-truck-loading" />}
              >
                <div className='my-2'>
                  <p className="mt-0">
                    Mercadoria recebida pela transportadora.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                position="right"
                className="vertical-timeline-element--work timeline-content"
                contentStyle={{ boxShadow: '0!important' }}
                contentArrowStyle={{ display: 'none' }}
                date={mountDateFormat(inicioDeRota)}
                iconStyle={getIconStyle(inicioDeRota)}
                iconClassName='cursor-default'
                icon={<i className="fas fa-truck" />}
              >
                <div className='my-2'>
                  <p className="mt-0">
                    Em Rota
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                position="right"
                className="vertical-timeline-element--work timeline-content"
                contentStyle={{ boxShadow: '0!important' }}
                contentArrowStyle={{ display: 'none' }}
                date={mountDateFormat(dataEntrega)}
                iconStyle={getIconStyle(dataEntrega)}
                iconClassName='cursor-default'
                icon={<i className="fas fa-check" />}
              >
                <div className='my-2'>
                  <p className="mt-0">
                    {situacaoEntrega === 'ENTREGUE' || situacaoEntrega === 'EM_OCORRENCIA' ? (
                      prettyfyStatus(situacaoEntrega)
                    ) : ('Entregue')}
                  </p>
                </div>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Col>
          <Col md="6">
            <Row className="mt-3 mt-md-0">
              <Col md="12">
                <p>
                  <b>Previsão Inicial:</b>
                  {' '}
                  {(previsaoInicial && (
                    <Badge
                      style={mountStatusBadge()}>
                      {mountDateFormat(previsaoInicial, showOnlyDayShift)}
                    </Badge>
                  )) || ('Aguardando cálculo')}
                </p>
                {showAllTrackingPredictions && (
                  <p>
                    <b>Previsão Reprogramada:</b>
                    {' '}
                    {(previsaoAtualizada && (
                      <Badge
                        style={mountStatusBadge()}>
                        {mountDateFormat(previsaoAtualizada, showOnlyDayShift)}
                      </Badge>
                    )) || ('Aguardando cálculo')}
                  </p>
                )}
                <p>
                  <b>Nota Fiscal:</b>
                  {' '}
                  {notaFiscal}
                </p>
                <Button color="primary" onClick={handleToggleCollapse}>
                  Ver {!collapse ? 'mais' : 'menos'}
                  {' '}
                  <i className="fas fa-extras" />
                </Button>
                <Collapse isOpen={collapse}>
                  {dataEntrega && (
                    <p>
                      <b>Entrega:</b>
                      {' '}
                      <Badge
                        style={mountStatusBadge(situacaoAndamento)}>
                        {mountDateFormat(dataEntrega)}
                      </Badge>
                    </p>
                  )}
                  <p>
                    <b>Situação:</b>
                    {' '}
                    {prettyfyStatus(situacaoEntrega)}
                  </p>
                  <p>
                    <b>Remessa:</b>
                    {' '}
                    {numeroRemessa}
                  </p>
                  <p>
                    <b>Remetente:</b>
                    {' '}
                    {remetenteNome}
                  </p>
                  <p>
                    <b>Destinatário:</b>
                    {' '}
                    {destinatarioNome}
                  </p>
                  {redespachoNome
                    && (
                      <p>
                        <b>Redespacho/Recebedor:</b>
                        {' '}
                        {redespachoNome}
                      </p>
                    )}
                  <p>
                    <b>Transportadora:</b>
                    {' '}
                    {transportadora}
                  </p>
                  <Button color="success" onClick={() => props.openModal(props.documentSelected, 'timeline')}>
                    Timeline
                    {' '}
                    <i className="fas fa-stream" />
                  </Button>
                  <Button color="info" onClick={() => props.openModal(props.documentSelected, 'comprovante')}>
                    Comprovante
                    {' '}
                    <i className="fas fa-file-image" />
                  </Button>
                  <Button color="secondary" onClick={() => props.openModal(props.documentSelected, 'deliveryLocation')}>
                    Local de Entrega
                    {' '}
                    <i className="fas fa-map-marker-alt" />
                  </Button>
                  {(alertas && alertas.length > 0) && (
                    <Button color="danger" onClick={() => props.openModal(props.documentSelected, 'alertas')}>
                      Alertas
                      {' '}
                      <i className="fas fa-bell" />
                    </Button>
                  )}
                </Collapse>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default DeliveryCard;
